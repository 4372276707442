var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('p',{staticClass:"text-danger"},[_vm._v("Campos obligatorios (*)")]),_c('p',{staticClass:"border-bottom border-primary text-primary pb-2"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"row mx-auto align-items-center"},[_c('div',{staticClass:"px-0 row mx-auto col-12"},[_c('div',{staticClass:"col-12 px-0 mb-2"},[_c('div',{staticClass:"text-primary mb-1"},[_c('strong',[_vm._v("Correo")]),(!_vm.emailNotRequired)?_c('span',{staticClass:"text-danger"},[_vm._v(" (*) ")]):_vm._e()]),(_vm.emptyEmail)?_c('el-checkbox',{staticClass:"mb-1",model:{value:(_vm.emailNotRequired),callback:function ($$v) {_vm.emailNotRequired=$$v},expression:"emailNotRequired"}},[_vm._v(" No cuenta con correo electrónico ")]):_vm._e(),_c('input-email',{attrs:{"rules":{
                        required: !_vm.emailNotRequired,
                        email: true
                    },"disabled":_vm.emailNotRequired},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('input-name',{staticClass:"col-12 px-0 mb-2",model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('input-first-surname',{staticClass:"col-12 pr-0 pl-0 mb-2",class:{ 'col-sm-6 pr-sm-3': _vm.type !== 2 },model:{value:(_vm.form.firstSurname),callback:function ($$v) {_vm.$set(_vm.form, "firstSurname", $$v)},expression:"form.firstSurname"}}),_c('input-second-surname',{staticClass:"col-12 pl-0 pr-0 mb-2",class:{ 'col-sm-6 pl-sm-3': _vm.type !== 2 },model:{value:(_vm.form.secondSurname),callback:function ($$v) {_vm.$set(_vm.form, "secondSurname", $$v)},expression:"form.secondSurname"}}),_c('select-document',{staticClass:"col-5 pr-1 pl-0 mb-2",class:{
                    'col-sm-6 pr-sm-3': _vm.type !== 3,
                    'col-sm-2': _vm.type === 3
                },model:{value:(_vm.form.documentType),callback:function ($$v) {_vm.$set(_vm.form, "documentType", $$v)},expression:"form.documentType"}},[_c('strong',{staticClass:"text-primary mb-1",attrs:{"slot":"label"},slot:"label"},[_vm._v(" Tipo de documento ")])]),_c('input-document',{staticClass:"col-7 pr-0 pl-1 mb-2",class:{
                    'col-sm-6 pl-sm-3': _vm.type !== 3,
                    'col-sm-4 pr-sm-3': _vm.type === 3
                },attrs:{"tag":"div","type-doc":_vm.form.documentType},model:{value:(_vm.form.documentNumber),callback:function ($$v) {_vm.$set(_vm.form, "documentNumber", $$v)},expression:"form.documentNumber"}},[_c('strong',{staticClass:"text-primary mb-1",attrs:{"slot":"label"},slot:"label"},[_vm._v(" Nro Documento ")])]),_c('ValidationProvider',{staticClass:"col-12 pr-0 pl-0",class:{ 'col-sm-6 pl-sm-3': _vm.type === 3 },attrs:{"tag":"div","name":"estado civil"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('strong',{staticClass:"text-primary mb-1"},[_vm._v("Estado Civil")]),_c('el-select',{staticClass:"w-100",attrs:{"placeholder":"Seleccione Estado Civil"},model:{value:(_vm.form.maritalStatus),callback:function ($$v) {_vm.$set(_vm.form, "maritalStatus", $$v)},expression:"form.maritalStatus"}},[_c('el-option',{attrs:{"label":"Soltero","value":"soltero"}}),_c('el-option',{attrs:{"label":"Casado","value":"casado"}})],1),_c('span',{staticClass:"text-danger d-block mt-1 f-13"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('input-phone',{staticClass:"col-12 pr-0 pl-0 mb-2",class:{ 'col-sm-3 pr-sm-3': _vm.type !== 2 },model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),(_vm.type !== 2)?_c('input-anexo',{staticClass:"col-sm-3 pr-0 pr-sm-3 pl-0 mb-2",model:{value:(_vm.form.anexo),callback:function ($$v) {_vm.$set(_vm.form, "anexo", $$v)},expression:"form.anexo"}}):_vm._e(),_c('input-cell-phone',{staticClass:"col-12 pl-0 pr-0 mb-2",class:{ 'col-sm-3 pl-sm-3': _vm.type !== 2 },attrs:{"rules":{ numeric: true, min: 9, max: 9 }},model:{value:(_vm.form.cellphone),callback:function ($$v) {_vm.$set(_vm.form, "cellphone", $$v)},expression:"form.cellphone"}}),_c('input-sex',{staticClass:"col-12 pl-0 pr-0 mb-2",class:{ 'col-sm-3 pl-sm-3': _vm.type !== 2 },model:{value:(_vm.form.sex),callback:function ($$v) {_vm.$set(_vm.form, "sex", $$v)},expression:"form.sex"}})],1)]),_c('div',{staticClass:"border-bottom border-primary my-3 pb-2"},[_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.subtitle))])]),_c('form-address',{attrs:{"data":_vm.form,"district-key":"districtId","province-key":"provinceId","department-key":"departmentId","urbanization-key":"urbanizationId","address-key":"address"},on:{"update:data":function($event){_vm.form=$event}}}),_vm._t("actions",null,{"invalid":invalid})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }
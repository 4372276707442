







































































































































import {
    Component,
    Vue,
    PropSync,
    Prop,
    Ref,
    Watch
} from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import FormAddress from '@/components/FormAddress.vue';
import InputName from '@/components/FormPerson/InputName.vue';
import InputFirstSurname from '@/components/FormPerson/InputFirstSurname.vue';
import InputSecondSurname from '@/components/FormPerson/InputSecondSurname.vue';
import InputSex from '@/components/FormPerson/InputSex.vue';
import InputPhone from '@/components/FormPerson/InputPhone.vue';
import InputCellPhone from '@/components/FormPerson/InputCellPhone.vue';
import InputEmail from '@/components/FormPerson/InputEmail.vue';
import InputAnexo from '@/components/FormPerson/InputAnexo.vue';
import InputDocument from '@/components/InputDocument.vue';
import SelectDocument from '@/components/SelectDocument.vue';
import { Customer } from '@/interfaces/customer';

@Component({
    components: {
        FormAddress,
        InputName,
        InputFirstSurname,
        InputSecondSurname,
        InputSex,
        SelectDocument,
        InputDocument,
        InputPhone,
        InputAnexo,
        InputCellPhone,
        InputEmail
    }
})
export default class FormCustomer extends Vue {
    @Ref() observer!: InstanceType<typeof ValidationObserver>;
    @PropSync('data', { required: true }) form!: Partial<Customer>;
    @Prop({ default: false, type: Boolean }) emptyEmail!: boolean;
    @Prop({ default: 1, type: Number }) type!: number;
    @Prop({ default: 'Datos del Agente' }) title!: string;
    @Prop({ default: 'Domicilio' }) subtitle!: string;

    emailNotRequired = false;

    @Watch('form.emptyEmail', { deep: true })
    handleForm(value: boolean) {
        if (value) {
            this.emailNotRequired = value;
        }
    }

    @Watch('emailNotRequired')
    handleEmailNotRequired() {
        this.form.email = '';
    }

    reset() {
        this.form = {};
        this.$nextTick(() => {
            this.observer.reset();
        });
    }
}
